import React from "react";
import { Link } from "gatsby";

interface Props {
  type?: "hero" | "product" | "landing" | "simple" | "blog";
  title?: string | null;
  summary?: string | null;
  divider?: boolean | undefined;
  brandMediaUrl?: string | null;
  brandMediaTitle?: string | undefined;
  brandMediaWidth?: string | undefined;
  buttonUrl?: string | null;
  buttonValue?: string | null;
  featureMediaUrl?: string | undefined;
  featureMediaTitle?: string | undefined;
  removePadding?: boolean | null;
  data?: any | undefined;
}

const defaultProps: Props = {
  type: "hero",
  buttonValue: "Learn More",
  removePadding: false,
  brandMediaWidth: "173px",
  divider: false,
};

const Banner: React.FC<Props> = (props) => {
  const {
    type,
    title,
    divider,
    summary,
    buttonUrl,
    buttonValue,
    brandMediaUrl,
    brandMediaTitle,
    brandMediaWidth,
    featureMediaUrl,
    featureMediaTitle,
    removePadding,
    data,
  } = props;

  return (
    <section
      className={`uk-section uk-cover-container uk-background-muted ${
        removePadding && "uk-padding-remove"
      }`}
    >
      <div data-uk-slider="autoplay: true; autoplay-interval: 5000; clsActivated: uk-transition-active">
        <div
          data-banner={type}
          className="c-banner-hero uk-position-relative"
          tabIndex={-1}
        >
          <ul className="uk-slider-items uk-child-width-1-1">
            {data &&
              data.map((item: any, index: number) => {
                return (
                  <BannerItem
                    key={`content_item_${index}`}
                    type={item.type}
                    title={item.title}
                    divider={item.divider}
                    summary={item.summary}
                    buttonUrl={item.buttonUrl}
                    buttonValue={item.buttonValue}
                    brandMediaUrl={item.brandMediaUrl}
                    brandMediaTitle={item.brandMediaTitle}
                    brandMediaWidth={item.brandMediaWidth}
                    featureMediaUrl={item.featureMediaUrl}
                    featureMediaTitle={item.featureMediaTitle}
                  />
                );
              })}
            {!data && (
              <BannerItem
                type={type}
                title={title}
                divider={divider}
                summary={summary}
                buttonUrl={buttonUrl}
                buttonValue={buttonValue}
                brandMediaUrl={brandMediaUrl}
                brandMediaTitle={brandMediaTitle}
                brandMediaWidth={brandMediaWidth}
                featureMediaUrl={featureMediaUrl}
                featureMediaTitle={featureMediaTitle}
              />
            )}
          </ul>
          <a
            className="uk-visible@m uk-light uk-position-center-left uk-position-small uk-hidden-hover"
            href="#"
            data-uk-slidenav-previous="true"
            data-uk-slider-item="previous"
          >
            <span className="c-visually__hidden">Previous</span>
          </a>
          <a
            className="uk-visible@m uk-light uk-position-center-right uk-position-small uk-hidden-hover"
            href="#"
            data-uk-slidenav-next="true"
            data-uk-slider-item="next"
          >
            <span className="c-visually__hidden">Next</span>
          </a>
        </div>
        {data && (
          <div className="c-banner-hero__dotnav">
            <ul className="uk-slider-nav uk-dotnav uk-flex-center">
              {data.map((item: any, index: number) => {
                return (
                  <li key={`content_item_${index}`} data-uk-slider-item={index}>
                    <a href="#" style={{ color: "white" }}>
                      index
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </div>
    </section>
  );
};

const BannerItem: React.FC<Props> = (props) => {
  const {
    type,
    title,
    divider,
    summary,
    buttonUrl,
    buttonValue,
    brandMediaUrl,
    brandMediaTitle,
    brandMediaWidth,
    featureMediaUrl,
    featureMediaTitle,
  } = props;

  return (
    <li>
      <div className="uk-flex@l uk-flex-center uk-flex-left@l uk-flex-middle c-banner-hero__wrapper uk-height-1-1">
        <img
          src={featureMediaUrl}
          alt={featureMediaTitle}
          className="uk-height-max-large uk-margin-auto uk-display-block uk-hidden@l"
        />
        {featureMediaUrl && (
          <div className="uk-child-width-1-2@s uk-visible@l">
            <div className="c-banner-hero__image uk-cover-container">
              <canvas width="895" height="895"></canvas>
              <img
                src={featureMediaUrl}
                alt={featureMediaTitle}
                data-uk-cover=""
              />
            </div>
          </div>
        )}
        <div
          className={`${
            type === "hero"
              ? "uk-container"
              : "uk-container uk-container-xlarge"
          } uk-flex-1 uk-position-relative`}
        >
          <div className="c-banner-hero__content">
            {brandMediaUrl && (
              <div
                className="c-banner-hero__brand"
                style={{ width: brandMediaWidth }}
                data-uk-slider-parallax="x: 100,-100"
              >
                <img
                  width=""
                  height=""
                  alt={brandMediaTitle}
                  src={brandMediaUrl}
                  className="uk-margin-small-bottom"
                />
              </div>
            )}
            {divider && <hr className="uk-divider-small" />}
            {title && (
              <h1
                className={`${
                  type === "landing"
                    ? "c-font-dax uk-h3"
                    : "uk-h5 uk-h3@m uk-h2@l uk-h1@xl"
                } uk-margin-small-top uk-margin-small-bottom`}
                dangerouslySetInnerHTML={{ __html: title }}
                data-uk-slider-parallax="x: 200,-200"
              />
            )}
            {summary && (
              <h2
                className={`${
                  type === "landing"
                    ? "uk-h3 uk-h3@m uk-h2@l uk-h1@xl"
                    : "uk-h4 uk-h4@m uk-h3@l uk-h2@xl"
                } uk-margin-small-top uk-margin-small-bottom`}
                dangerouslySetInnerHTML={{ __html: summary }}
                data-uk-slider-parallax="x: 200,-200"
              />
            )}
            {buttonUrl && (
              <div data-uk-slider-parallax="x: 200,-200">
                <Link
                  to={buttonUrl}
                  className="uk-button uk-button-primary uk-margin-small-top"
                >
                  {buttonValue || "Learn More"}
                  <span
                    className="c-visually__hidden"
                    dangerouslySetInnerHTML={{
                      __html: `About ${brandMediaTitle}`,
                    }}
                  />
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </li>
  );
};

export default Banner;

Banner.defaultProps = defaultProps;
